@import "section.library.filters.less";
//////////Layout///////////////
.filters-and-products{
	.row(12);
	& .layer {
		max-width: 1480px;
		margin: 0 auto;
	}
	& aside.nav{
		@media @mobile, @tablet{
		width: 100%;
		};
		@media @desktop{
			.row(3);
			padding: 0 20px;
		};
	}

}
.products{
 .row(10);
 @media @mobile, @tablet{
 	.row(12);
 };
 @media @desktop{
	.row(9);
};
 float: right;
 & .product-list{
 	display: table;
 }
}



@list-controller-height: 60px;
//////////BARRE DE BOUTONS///////////////

.list-controller{
.container(fluid, left,@list-controller-height);
line-height: @list-controller-height;
margin-top: -1px;
.clearfix();
}

.list-switch{
	display: inline-block;
	float: right;
	list-style: none;
	vertical-align: middle;
	line-height: inherit;
	height: inherit;
	& .label{
		float: left;
		display: inline-block;

		@media @mobile{
			display: none;
		};
		& p{
			.font(12);
			color: @font-light-color;
			display: inline-block;
			vertical-align: middle;
			padding: 0 @simple-padding;
			margin:0;
		}
	}  
	& li {
		display: inline-block;
		height: inherit;

		& a{
			display: inline-block;
			vertical-align: middle;
			height: inherit;
			width: @list-controller-height;
			text-align: center;
			& span {
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				height: inherit;
				font-size: 0;
				&:after{
				.font-size(26);
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				}
			}
			
			
		}
		
	}
	& a.active {
		background-color: @brand-primary;
		height: 100%;
		& span{
			&:after {
			color:#6e6e6e;
		}
		}
		
	}

}

.selection {
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	padding-left: @simple-padding;
	
	& input {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    	
    	&:before{
    	width: 20px;
    	height: 20px;
    	display: inline-block;
    	vertical-align: middle;
    	margin-left: -5px;
    	vertical-align: middle;
    	line-height: 20px;
    	}
	}
	
	& .dropdown {
	margin: 0 @simple-padding;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;    
	    & a {
	    	line-height: inherit;
	    }
	}
}

.switch-to-product-block-list{
	& span{
		.icon-grid();	
	}
	
}

.switch-to-product-simple-list{
	& span{
		.icon-list_burger();	
	}
	
}


////////// No results ////////

#searchNoresult{
	padding-left: 30px;
	margin-bottom: 120px;
	padding-top: 160px;

}