
@auth-text-color: #ffffff;

body.auth {
 margin: 0;
 height: 100%;
 min-height: 100%;

  & .auth-container {
    display: block;
    width: 100%;
    height:100%;
    text-align: center;
    background-color: transparent;
    padding: @triple-padding 0;
    @media @mobile{
    };
    &:before {
      display:inline-block;
      content: '';
      height:100%;
      width:0;
      vertical-align:middle;
    }
    & .auth-centered{
      .container(350);
        @media @mobile{
        .col(10);
        float: none;
        display: inline-block;
        vertical-align: middle;
        };
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      .border-radius();
      padding: @simple-padding;
      padding-top: 0;
    }

  }
  
  .login-logo {
    text-align: center;
    & a{
      display: block;
      .row(12);
      .bg-contain(@white-logo-png);
      height: 120px;
      margin-bottom: @double-padding;
    }
    p {
      display: none;
      color: @auth-text-color;
      .font(14);
      margin-bottom: 20px;
    }
 
  }
  .container-fluid{
    
  }
////////////////////////// LANG SWITCH //////////////////////////////


  .lang-switch{
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      background-color:white;
      padding: 10px 10px 10px 10px;


    & li{
      list-style: none;
      display: block;
      float: left;
      margin: 0;
      text-transform: uppercase;
      
      &:first-child {
      &:after {
      content: "|";
      padding: 0 10px;
      }
    }
      a{
      color:#c2c2c2;
      }
     
      a:hover{ 
     
      color: @brand-second;
      text-decoration: none;
      font-weight: bold;
      }
    }
    
    .active{
      
      & a {
        color:@brand-primary;
        font-weight: bold;
      }
    }
  }
////////////////////////// LANG SWITCH //////////////////////////////

  

    

  h2, h4 {
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    .font(32);
    font-weight: 700;
    margin-top: 0;
  }
    
  .form-group .login {
    label {
      color: @auth-text-color;
     .font(13);
     text-transform: none;
     font-weight: 300;
    }

  }
// Pass recover link in login template
  .pass-recover { 
    height: auto;
    margin: 15px 0 0px 0;
    clear: both;
    
    a {
      color: @auth-text-color;
      font-size: 12px;
      display: block;
    }
    
    a:hover{color: @brand-primary;}

  } 


// Registration link in login template
  .oauth-text{
    .container(fluid);
    margin-top: @double-padding;
    margin-bottom: @double-padding;
    position: relative;
    
      & hr:first-child{
      left:0
      }
      
      & hr {
      margin-top: 20px;      
      min-width: 10%;
      height: 2px;
      display: block;
      position: absolute;
      top:0;
      margin: 0;
      }

      & hr:last-child{
      right:0
      }
      & p {
        position: absolute;
        top:0;
        margin-top: -10px; 
        padding-left: @simple-padding;
        padding-right: @simple-padding;
        text-align: center;
        position: relative;
        z-index: 2;
        // background-color: @bg-default-color;
      }

  }



  /////////////////////// SIGIN UP /////////////////

/////OLD

  .registration-access {
    margin: 30px 0 30px 0;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: content-box;
    overflow:auto;
    &::after{ 
    clear: both;
    content: "";
    display: table;}


    a{
      width: 120px;
      float: right;

    }
  }

  footer{
    background-color: transparent;
    margin-bottom:0;
    margin-top: 0;
   
   .contact {
      text-align: left;
      font-size: 12px;
      display: block;
      color: #fff;
      margin: 13px auto auto auto;
      color: #737373;
      text-decoration: none;
    }
  }
}



/////////////////////////// SIGN UP LINK


.request-access{
  
  padding-top: @double-padding;
  margin: 10px 0;
  text-align: center;
  .font(13);
  color: #ffffff;
}

  .help-block{
      color: @auth-text-color;
     .font(13);
     text-transform: none;
     font-weight: 300;
  }



#user_registration{
  & label{
  color:#ffffff;
  }
}


