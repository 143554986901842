@selection-height: 60px;

body.visual {
  .selection {
    padding-left: 0;
    height: @selection-height;
    line-height: @selection-height;
  }
  & .corporate-container {
    .col(8);
    .push(2);
    padding: @simple-padding;
    margin-top: @double-padding;
  }
  & .list-controller {
    line-height: @selection-height;
    height: @selection-height;
    border-bottom: 0;
    & .item {
      line-height: @selection-height;
      height: @selection-height;
      & input {
        line-height: @selection-height;
        height: @selection-height;
      }
      & .dropdown {
        float: left;
      }
    }
  }
  /*ul*/
  .container-visual-docs {
    list-style: none;
    margin: 0;
    padding: 0;
    clear: both;
    /*li*/
    .document {
      color: @gray;
      position: relative;
      height: @selection-height;
      line-height: @selection-height;
      border-bottom: 1px solid @gray-light;
      &:first-child {
        border-top: 1px solid @gray-light;
      }
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: @font-ultralight-color;
        background-color: @brand-primary;
        border-color: lighten(@brand-primary, 10%);
        .actions button {
          color: @font-ultralight-color;
        }
        .title p{
          color: @font-ultralight-color;
        }
      }
      &.selected {
        color: @font-ultralight-color;
        background-color: @brand-primary;
        border-color: lighten(@brand-primary, 10%);
        .actions button {
          color: @font-ultralight-color;
        }
        .title p{
          color: @font-ultralight-color;
        }
      }

      input[type="checkbox"] {
        font-size: 16px;
        height: @selection-height;
        margin: 0 8px;
      }

      .selector-container {
        line-height: @selection-height;
        display: inline-block;
        height: @selection-height;
        vertical-align: top;
        padding-left: 14px;
      }
      .thumbnail-icon {
        display: inline-block;
        height: @selection-height;
        margin: 0 @double-padding;
        .file-icon {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .title {
        display: inline-block;
        line-height: @selection-height;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-family: 'avalon-plain';
        vertical-align: top;
        & p {
          line-height: @selection-height;
          margin: 0;
        }
      }

      .actions {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        padding: 0;
        line-height: inherit;
        height: 100%;
        // top: 50%;
        // margin-top: -20px;
        button {
          color: lighten(@gray-light, 20%);
          background-color: transparent;
          display: inline-block;
          &:hover {
            background-color: lighten(@brand-primary, 10%);
          }
          & p {
            display: none;
          }
          border: 0;
          padding: 0;
          width: 60px;
          line-height: @selection-height;
          height: 100%;
          vertical-align: top;
        }
      }
    }
  }
  .intro {
    color: @font-light-color;
    font-family: @font-family-base;
    .font(15);
    margin-bottom: 40px;
    & p {
      margin: 0;
      text-align: center;
    }
  }
}


.actions-and-products > .actions .btn {
  &:extend(.btn-block);
  max-width: 300px; // Else they can be huuuuge
  font-family: 'avalon-plain';
  font-size: 13px;
  color: @font-base-color;
  // Spacing between buttons
  & + .btn {
    margin-top: 5px;
  }

  text-align: right;
  padding: 16px 16px 14px 16px;

  // Align glyphicons
  .glyphicon {
    float: left;
    font-size: 16px;
  }
}
