aside.nav{
.col(2); 
 & form {
   & .header{display: none;}
   & .reset{display: none;}
  } 
}

#filters{ 
  height: 100%;

  hr{display: none;}
}

#filters{
  & >.form-group{
       & label.required {
          .font(16);
          font-weight: 700;
          color: @font-light-color;
          text-transform: uppercase;
          float: initial;
       }
  }
}

/////////////// SEARCH TYPE ////////////////

.search_type{
  & input[type="radio"] + label{
    .font(12)!important;
    &:hover{
      color: @brand-primary !important;
      cursor: pointer;

    }
  }

  & input[type="radio"]:checked + label {
    color: @brand-primary !important;
    .font(12)!important;
  }
}

//////////////// FACETS  ///////////////////
.facets-container{
        overflow: hidden;
          border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none
}
.facets {
  max-height: 450px;
  & h4{
    .font(16);
    font-weight: 700;
    color: @font-light-color;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 0;
  }
  & ul {
    width: 100%;
    max-height: 400px;
    border-collapse: collapse;
    padding-bottom: 20px;
    overflow-y: auto;
    //&::-webkit-scrollbar { width: 0 !important }
  }
  & ul > li {
    min-height: 25px;
    list-style: none;
    & input[type="checkbox"]{
      display: none;
    }
    & label {
      .font(13);
      font-weight: 300;
      color: @font-light-color;
      text-transform: none;
      &:hover{
        color: @brand-primary;
        cursor: pointer;
      }
    }
  }
}


//////////////// FILTERS ///////////////////

.form-group{
  & .checkbox{
    & input[type='radio']{
      display: none;
    }
    & input[type='checkbox']{
      display: none;
    }
    & label{
      .font(13);
      font-weight: 300;
      text-transform: none;
    }
    & label[for="filters_search_type_0"]{
      color: @brand-primary;
    }
  }
}
