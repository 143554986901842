/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 7, 2018 */
@font-face {
  font-family: 'gilroyextrabold';
  src: url('../fonts/radomir_tinkov_-_gilroy-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/radomir_tinkov_-_gilroy-extrabold-webfont.woff') format('woff'),
  url('../fonts/radomir_tinkov_-_gilroy-extrabold-webfont.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gilroyheavy';
  src: url('../fonts/radomir_tinkov_-_gilroy-heavy-webfont.woff2') format('woff2'),
  url('../fonts/radomir_tinkov_-_gilroy-heavy-webfont.woff') format('woff'),
  url('../fonts/radomir_tinkov_-_gilroy-heavy-webfont.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gilroymedium';
  src: url('../fonts/radomir_tinkov_-_gilroy-medium-webfont.woff2') format('woff2'),
  url('../fonts/radomir_tinkov_-_gilroy-medium-webfont.woff') format('woff'),
  url('../fonts/radomir_tinkov_-_gilroy-medium-webfont.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'gilroyregular';
  src: url('../fonts/radomir_tinkov_-_gilroy-regular-webfont.woff2') format('woff2'),
  url('../fonts/radomir_tinkov_-_gilroy-regular-webfont.woff') format('woff'),
  url('../fonts/radomir_tinkov_-_gilroy-regular-webfont.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}