
//// This must be remove from this styleshit

@header-height: 70px;
@header-right-height: @header-height;
@nav-height: @header-height;
@content-header-height: 160px;
@blade-element-color: @font-base-color;
////////////////////////////  ####### CONTENT HEADER - SECTIONS TITLES ########    ////////////////////////////  

.content-header {
  .container(fluid,left, @content-header-height);
  line-height: @content-header-height;
  font-family: @font-family-base;
    @media @mobile, @tablet{
    margin-top: 0;
    .container(fluid,left, @content-header-height - 50px);
      line-height: @content-header-height - 50px;

  }
  & .layer {
    max-width: 1480px;
    margin: 0 auto;
  }
  h1 {  
    margin:0;
    padding:0;
    .push(1);
    display: inline-block;
    vertical-align: middle;
    font-family: 'gilroyheavy';
    .font(48);
    font-weight: 100;
    color: @brand-primary;
  }
  & a {
    height: inherit;
    color: @brand-primary;
  }
  h2 {
    .font(32);
    display: none;
    vertical-align: middle;
    color: @brand-primary;
  }


  .media-counter {
    margin:0;
    padding:0;
    .pull(1);
    font-family: 'gilroyheavy';
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    float: right;
    text-transform: uppercase;
    color: @brand-primary;
    text-align: left;

    .number {
      .font-size(40); 
      display: inline-block;
    }
    .unit {
      .font-size(14);
      padding-left: 0.5rem;
      display: inline-block;
      
    }
  }
}




////////////////////////////  ####### HEADER ########    ////////////////////////////  


header {
.horizontal-nav();  
}


.vertical-nav{
  .col(12);
  margin-top: 40px;
  

  
    .header-bar-right {
      position: absolute;
      top: 0;
      right: @simple-padding;
      height: @header-right-height;
      

///// CART //////

//     .cart {
      
//       // vertical-align: middle;
//       line-height: @header-right-height;
//       height: @header-right-height;
//       float: right;
//       display: inline-block;

//         & .cart-title{
//           color: #ffffff;
//           .font(12);
//           display: inline-block;
//           vertical-align: middle;
//           float: right;
//           line-height: @header-right-height;
//           margin-right: @simple-padding;
//           height: @header-right-height;
//           // float: right;
//           // text-align: right;
//         }

//         & .glyphicon-shopping-cart{

//           display: inline-block;
//           vertical-align: middle;
//           float: right;
//           line-height: @header-right-height;
//           height: @header-right-height;
//           .icon-cart();
//             &:after{
//               line-height: @header-right-height;
//               color: #ffffff;
//               position: relative;
//               top: -20px;
//               z-index: 0;
//           }
//         }

//         .cart-item-counter {
//           position: relative;
//           display: block;
//           width: 18px;
//           height: 18px;
//           border: 1px solid #fff;
//           background-color: #9b8097;
//           border-radius: 50%;
//           color: #fff;
//           top: 3px;
//           right: -20px;
//           left: auto;
//           text-align: center;
//           padding-top: 3px;
//           font-weight: 700;
//           font-style: normal;
//           z-index: 2;
//           .font(10);
          
           
//         }
// /////////////HOVER CART////////////////////
//         &:hover {
        
//         }
//     }



///// ACCOUNT + CONNECT ////// 
   
    .account/*.dropdown*/   {
      display: inline-block;
      float: right;
      height: @header-right-height;
      line-height: @header-right-height;
      margin-left: @double-padding;
      
         
  

        & a.dropdown-toggle {
          display: inline-block;
          vertical-align: middle;
          float: right;
          .font-size(12);
          color: #ffffff;
          line-height: @header-right-height;
          & .avatar{
            .container(40,40);
            display: inline-block;
            padding: 0;
            .icon-avatar();

            &:after{
              font-size: 40px;
              line-height: @header-right-height;
              display: inline-block;
              vertical-align: middle;
              float: right;
              color: @blade-element-color;
            }
          }
          & .caret {
            margin-left: 5px;
            display: inline-block;
            border-color: @blade-element-color;
          }
          & span{
            display: none;
          }
        }
    }
  }



  .account .dropdown-menu {
    
    // .icon-avatar();
    // .font(12);
  }


///////////////////////// SEARCH BAR ///////////////
  & .form-search {
    .row(10);
    float: right;
    padding-left: 35px;
    
    & .input-group{
      position: relative;
      & input {
      height: 45px;
      box-shadow: none;
      width: 100%;
      border: none;
      max-width: 100% !important;
      .font(16);
      color: #373334;
      background-color: #b3b3b3;
    }

    button, button:hover, button:active {
      width: 45px;

      .icon-search();
      &:after{
        color: #373334;
        .font-size(20);
      }
      position: absolute;
      top: 2px;
      right: 10px;
      height: 50px;
      background-color: transparent;
      border: 0;  
      margin: 0;
      padding: 0
    }
  }
}
  
}

.horizontal-nav{
  .container(100%,none,@nav-height + @header-height);
  
  @media @mobile, @tablet{
    position: relative;
    height: auto;
  }
  z-index: 1000;
  margin: 0 auto;
  padding: 0;
  border: 0;
  max-width: 1480px;
    .header-bar-right {
      height: inherit;
  .form-search {
    width: 360px;
    margin-top: 50px;
    margin-right: 20px;
    input {
      height: 50px;
      background-color: #fafafa;
      border: 1px solid #ebebeb;
      box-shadow: none;
    }
  }

}
//////////////////   ACCOUNT BLADE + CART ////////////////////
.header-bar-right {
    position: absolute;
    top: 0;
    right: @simple-padding;
    height: @header-right-height;
    

/////////////////////////////////////////////////////////////////////////////////////// CART //////

.cart {
      
      line-height: @header-right-height;
      height: @header-right-height;
      display: inline-block;

        & .cart-title{
          @media @mobile{.hide();};
          color: @font-base-color;
          .font(12);
          display: inline-block;
          vertical-align: middle;
          line-height: @header-right-height;
          margin-right: @simple-padding;
          height: @header-right-height;
          text-align: right;
        }

        & .glyphicon-shopping-cart{
          display: inline-block;
          vertical-align: middle;
          line-height: 30px;
          height: 30px;
          font-family: hollyglyph !important;
          text-transform: none;
          .icon-cart();
            &:after{
              position: relative;
              top: -5px;
              z-index: 0;
              font-size:40px;
          }
        }

        .cart-item-counter {
          position: relative;
          font-family: @client-font-family;
          display: block;
          width: 22px;
          height: 22px;
          border: 2px solid #fff;
          border-radius: 50%;
          color: #ffffff;
          top: -8px;
          right: -32px;
          left: auto;
          text-align: center;
          padding-top: 2px;
          font-weight: 700;
          .font(10);
          z-index: 10;
          
           
        }
/////////////HOVER CART////////////////////
        &:hover {
        
        }
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///// ACCOUNT + CONNECT ////// 

  .account {
    display: inline-block;
    // float: right;
    // height: @header-right-height;
    line-height: @header-right-height;
    margin-left: @double-padding;  
    &:hover{
      & a.dropdown-toggle {
        & .avatar{
          &:after{
            color: @brand-primary;
            }
          }
        }
    }  

    & a.dropdown-toggle {
      display: inline-block;
      vertical-align: middle;
      float: right;
      .font-size(12);
      color: @blade-element-color;
      display: inline-block;
      vertical-align: middle;
      float: right;
      line-height: @header-right-height;
      & .avatar{
        .container(40,40);
        display: inline-block;
        .icon-avatar();
        padding: 0;

        &:after{
          font-size: 40px;
          line-height: @header-right-height;
          display: inline-block;
          vertical-align: middle;
          float: right;
          color: @blade-element-color;
        }
      }
      & .caret {
        margin-left: 5px;
        display: inline-block;
        line-height: @header-right-height;
        height: @header-right-height;
      }
      & span{
        display: none;
      }
    }
    &.open,
    &:hover {    
    }
  }
}

  .connect{
    display: inline-block;
    height: @header-right-height;
    line-height: @header-right-height;
    margin: 0 @simple-padding 0  @double-padding;
    & .btn{margin: 0;}
  }



.account/*.dropdown*/   {

      &.open,
      &:hover {
        color: @brand-primary;
        & a.dropdown-toggle {
           .caret {
            color: @brand-primary;
            }
          }
      }
      a.dropdown-toggle {
        display: inline-block;
        & > span {
          padding: 10px 0;
          display: inline-block;
        }
        .caret {
          margin-left: 5px;
        }
        &:hover,
        &:focus {
          avatar: transparent;
          text-decoration: none;
        }
      }
      .dropdown-menu {
        right: 0;
        left: auto;

        // Prevent header override
        > li > a {
          color: @font-base-color;
          &:hover,
          &:focus {
            color: @font-dark-color;
          }
        }
      }
      .picture {
        // .square(@header-height - ((@header-height - 34px)));
        padding: ((@header-height - 34px) / 2);
        vertical-align: top;
      }
    }
  }


///////////////////ACCOUNT /////////////////////////



  .account .dropdown-menu {
    top: @header-height + 5px;

    &:before {
      position: absolute;
      top: -10px;
      right: 19px;
      display: inline-block;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ccc;
      border-left: 10px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    &:after {
      position: absolute;
      top: -9px;
      right: 20px;
      display: inline-block;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #ffffff;
      border-left: 9px solid transparent;
      content: '';
    }
  }



  /////////////////////////////   LANG SWITCH ///////////////////////


    .login, .lang-switch {
    display: inline-block;
    height: @header-height;
    line-height: @header-height;
     > li {
      display: inline-block;
      padding: 0 @simple-padding;
      text-transform: uppercase;
      height: 20px;
      line-height: 20px;
      vertical-align: middle;
      &:first-child {
        border-right: 1px solid #b5b2b2;
      }

      & a:hover {
        text-decoration: none;
        color: #b5b2b2;
      }
      &.active {
      }
      &.active a {
        color: @brand-primary;
        font-weight: bold;
      }
    }
    & a {
      .font(13);
      color: @header-font-color;
      display: inline-block;
      height: inherit;
      line-height: inherit;
      vertical-align: middle;

    }
  }

