
.block-not-cropped{
  padding: @double-padding @double-padding @double-padding @simple-padding;
  & li {      
    position: relative; 
    list-style: none;    
    z-index: 0;
    @item-gutter: 15px;
    padding-left: @item-gutter;
    padding-bottom: @item-gutter;
    .items(@items-default);
    @media @xlarge{.items(@items-xlarge);}
    @media @large{.items(@items-large);}
    @media @desktop{.items(@items-desktop);}
    @media @tablet{.items(@items-tablet);}
    @media @mobile{.items(@items-mobile);}
    .border-radius();
    
    & .item-paginate {
      margin-top: 1px;
      margin-left: -1px;
      height: @item-height + 5px;
      display: table;
      padding: @double-padding; 
      background-color: transparent !important;  
      outline: 1px solid @blocks-paginate-outline;
      
      & a {
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        .font(22);
        color: @brand-second;       
        
        &:hover{
          text-decoration: none; 
        }
      }
    }
    
    & .item{     
      outline: 1px solid @blocks-outline; 
      width: 100%;
      border-bottom: 5px solid transparent;
      box-sizing: border-box;
      height: @item-height;
      .drop-shadow();
      & .image {
        .border-radius();
        display: table;
        position: relative;
        width: 101%;
        @media @desktop, @tablet, @mobile {height: 170px; line-height: 170px; max-height: 170px;};
        @media @mobile {height: 200px; line-height: 200px; max-height: 200px;};

        max-height: @img-height;
        height:@img-height;
        line-height: @img-height;     
        & a{    
          display: table-cell;
          position: relative;
          cursor: pointer;
          width: inherit;
          text-align: center;
          vertical-align: middle;
          height: inherit;
          line-height: inherit;
          max-height: inherit;
          .border-radius();
          & .icon-only{
            width: 100%;
            height: 100%;
            background-color: @soft-border-color;
            line-height: inherit;
            padding: 0;
            & span{
              .file-icon-large();
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }


      & .caption{
          height:@cartouche-height;
          display: table;
          width: 100%;

          .media-title{
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            text-align: center;

            & a{
              .font(12);
              font-weight: 300;
              color: @font-ultralight-color;
              // text-transform: uppercase;
              text-align: center;
            }
          }
          & .extra-fields{
            width: 100%;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            & p{
              .font(11);
              margin: 0;
              color: #A2A2A2;
            }
          }
      }

      & .actions {
        left: @item-gutter;
        right: -2px;
        width: auto;
        display: none;
        @bg-actions-hover();
        position: absolute;
        top: 0;
        z-index: 10;
          @media @desktop, @tablet{
          height: @img-height - 28px;
          line-height: @img-height - 28px;    
          };
        height: @img-height;
        line-height: @img-height;
        & a{
            width: 100%;
            height: 100%;
            display: block;
            margin-left: - @item-gutter;
            line-height: @img-height;
          }
        & .bnt-wrapper{
          vertical-align: middle;
          display: inline-block;
          width: 100%;

            & .item-btn {
              @media @large, @xlarge{ width: 200px;};
              width: 160px;
              margin-top: 5px;
              display: block;
              vertical-align: middle; 
              &:first-child{margin-top: 0;}
              &:after{display: none;}
            }
          }
        }
      
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        z-index: 11;
        padding: @simple-padding;
      }    
    }  
  }
}
 
// ############### ANIMATIONS & BEHAVIOURS ###############  //
.block-not-cropped{
  li{    
    &:hover{
      z-index: 3; 
     
      & .item-paginate{
      outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }

      & .item{
        .transition(transform, 0.2s);
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        -ms-transition-delay: 0s;
        transition-delay: 0s;
        .scale(1.05);
        & a {
          color: @brand-primary;
          cursor: pointer;
        }
        & .image{
          & .thumbnail-wrapper{
            outline: 3px solid @brand-primary;
            background-color: #c2dae1;
          }
          

        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-primary;
            }
          }
        }

        & .actions{
          left: 0;
          display: inline-block;
        }
      }

      .selector-container{
         visibility: visible;
        z-index: 20;
        
        & input { 
        display: inline-block;
        line-height: 10px; 
        .icon-checkbox_off();
        z-index: 999;
      
          &:before{
            color:#ffffff;    
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }

      .bnt-wrapper{
        display: inline-block !important;
      }
    }

///END HOVER


    &.selected {
      z-index: 3;

      & .item-paginate{
        outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }

      & .item{
        & a {
          color: @brand-primary;
          cursor: pointer;
        }
        & .image{
          & .thumbnail-wrapper{
            outline: 3px solid @brand-primary;
            background-color: #c2dae1;
          }


        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-primary;
            }
          }
        }

        & .actions{
          display: inline-block;
          & .bnt-wrapper{
            display: none;
          }
        }
      }

      .selector-container{
        visibility: visible;
        z-index: 20;

        & input {
          display: inline-block;
          line-height: 10px;
          .icon-checkbox_off();
          z-index: 999;

          &:before{
            color:#ffffff;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }
    }  
  }
}
