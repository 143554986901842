body{
 
// @media @xlarge {font-size:@font-xlarge;}
// @media @large {font-size:@font-large;}
// @media @desktop {font-size:@desktop;}
// @media @tablet {font-size:@tablet;}
// @media @mobile {font-size:@mobile;}
	font-family: @font-family-base;
	font-weight: 100;
	font-size: 62.5%;

}

.font-size(@fontSize) {
	@remValue:@fontSize/10;
	@pxValue: @fontSize;
	font-size: ~"@{pxValue}px"; 
	font-size: ~"@{remValue}rem";
}

.font(@fontSize) {
	@remValue:@fontSize/10;
	@pxValue: @fontSize;
	@lineHeight: (ceil((@fontSize + 5) / 5))*5;
	@remLineHeight: @lineHeight/10;
	font-size: ~"@{pxValue}px"; 
	font-size: ~"@{remValue}rem";
	line-height: ~"@{lineHeight}px"; 
	line-height: ~"@{remLineHeight}rem";
}


@font-over-img:{color: #ffffff;};

h2	{@h1();}
h2 	{@h2();}
h3 	{@h3();}


::selection {
  background: #ACCEF7;
  color: #ffffff;
}
::-moz-selection {
  background: @brand-primary;
  color: #ffffff;
}


