@body-min-width: none;

html {
	background: #000;
	&:before {
		content:"";
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background: url(../img/background/exhibit-bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
	}
}

body{
	&.auth {
		background: url(../img/background/exhibit-bg.jpg);
		background-size:cover;
	}
	&.mediatheque{
		background: url(../img/background/mediatheque.jpg);
		background-size:cover;
		background-position-y: -340px;
	}
	&.documentation{
		background: url(../img/background/documentation.jpg);
		background-size:cover;
	}
	&.identity {
		background: url(../img/background/iconographie.jpg);
		background-size:cover;
	}
	& > .container{
		position: relative;
		min-height: 700px;
		height: 100%;
		margin: 0 auto;
		overflow: auto;
	}
}

html, body {
	margin: 0;
	padding: 0;
	border: none;
	font-family: @font-family-base;
	font-weight: 100;
	font-size: 62.5%;
	min-width: @body-min-width;
	position: relative;
	height: 100%;
}


hr {
  margin-top:    @double-padding;
  margin-bottom: @double-padding;
  border: 0;
  border-top: 1px solid @separator-color;
}
