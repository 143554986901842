body.basket {
  & .container {
    font
    & header{
      background-color: transparent;
      .row(12);
      & .form-search{display: none;}
    }
    & .content-header {

      display: none;
    }
}
  
  
  .actions-and-products {
    
    padding-bottom: 20px;
    
    .row(12);
    float: none;
    @media @xlarge{.row(8);float: none;};
    @media @large{.row(10);float: none;};
    @media @desktop, @tablet, @mobile{.row(12);float: none;};

  
    float: none;
    clear: both;
    margin: auto;

    & .products{
     margin-top: 0; 
    }

    & .product-block-list{
     .cart-block-not-cropped();
    }

    & .product-simple-list{
      .list-cart-not-cropped();
    }
    
    & > .actions {
      
      .intro{display:none;}
      float: left; 
    }

    & > .products {
      // .make-md-column(9, 0);
      .row(12);
      border: none;
      margin-bottom: 50px;
      min-height: 421px;
      & .selection input {
        width: @list-controller-height;
      }
    }
    & .empty-cart {
      color: @font-base-color;
      .font(16);
    }
  }
}



.cart-header{
  height: 85px;
  line-height: 85px;
  .row(12);
  float: left;
  & h1{
    float: left;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    .font(22);
    text-transform: uppercase;
    color: @font-base-color;
    margin: 0;
    line-height: 85px;
    padding: 0 @simple-padding;
  }
  & .list-switch{
    display: inline-block;
    line-height: 85px;
    vertical-align: middle;
  }
}


.bar-buttons{
    display: inline-block;
    vertical-align: middle;
  & form{
    display: inline-block;
    float: left;
  }

}


.cart-breadcrumb{
  margin-top: 50px;
  .row(12);
  // margin-left: @double-padding;
  padding: 0 @double-padding;
  height: 40px;
  background-color: #4d4d4d;
  

  & a{
    display: inline-block;
    color: #b3b3b3;
    font-weight: 700;
    .font-size(12);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;
  }
  & p{
    display: inline-block;
    color: #ffffff;
    font-weight: 700;
    .font-size(12);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;

  }
  & .breadcrumb-separator{
    display: inline-block;
    color: #ffffff;
    margin: 0;
    padding:0;
    color: #afafaf;
    .icon-right_thin();
    line-height: 40px;
    float: left;
    &:after{
      display: inline-block;
    }
  }
  & .cart-counter{
    display: inline-block;
    color: #bdbdbd;
    font-weight: 700;
    .font-size(11);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: right;
    
  }
  & .unit{
    display: inline-block;
    color: #bdbdbd;
    font-weight: 700;
    .font-size(11);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: right;
    padding-left: 0.3rem;
  }
}