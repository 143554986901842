.cart-list{
  margin-bottom: @double-padding;
  > li {
    .row(12);
    position: initial;
    list-style: none;
    z-index: 0;
    display: block;
    height: @list-item-height;
    overflow: auto;
    float: none;
    box-sizing: border-box;
    margin-bottom: 2rem;
    font-family: @font-family-base;
    & .item-paginate {
      height: @list-item-height - 4px !important;
      display: table;
      padding: @double-padding;
      margin-right: 1px;
      background-color: transparent !important;
      outline: 1px solid @brand-second;
      & a {
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        .font(22);
        color: @brand-second;

        &:hover{
          text-decoration: none;
        }
      }
    }


    > .item{
      overflow: hidden;
      height: @list-item-height - 2px;
      box-sizing: border-box;
      // .border-radius();
      border-right: 5px solid transparent;
      width: 100%;
      line-height: @list-item-height;
      background-color: #d7d7d7;
      position: relative;
      &.active {
        background-color: #f2f2f2;
      }
      & .image {
        width: @list-item-height * 1.5;
        height: @list-item-height;
        max-height: @list-item-height;
        display: block;
        position: relative;
        background-color: @thumbnail-background-color;
        overflow: hidden;
        float: left;

        > a{
          display: table-cell;
          position: relative;
          cursor: pointer;
          width: inherit;
          height: inherit;
          text-align: center;
          max-height: @list-item-height;
          vertical-align: middle;
          & .portrait{
            & img{
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              -webkit-transform: translate(0%,-50%);
              -ms-transform: translate(0%,-50%);
              transform: translate(0%,-50%);
              left: 0;
            }

          }
          & .landscape{
            & img{
              height: 100%;
              width: auto;
              max-width: none;
              max-height: none;
              position: absolute;
              left: 50%;
              top: 0;
              -webkit-transform: translate(-50%,0%);
              -ms-transform: translate(-50%,0%);
              transform: translate(-50%,0%);
            }
          }
          & .thumbnail-wrapper{

          }

          & .icon-only{
            & .file-icon{
              display: none;
              background: transparent;
              background-image:none;
            }
            .icon-cart();
            &:after{
              .font-size(60);
              margin-top: -30px;
              display: block;
            }
            width: 100%;
            height: @list-item-height;
            padding:25px;
            background-color: @soft-border-color;
            line-height: @list-item-height;
            & span{
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }

      & .caption{
        height:@list-item-height;
        .col(8);
        @media @tablet {
          .col(6);
        }
        @media @mobile {
          .col(4);
        }
        vertical-align: middle;

        .media-title{
          > a{
            .font(24);
            font-weight: normal;
            color: @brand-second;
            text-align: left;
          }
          .font(24);
          display: inline-block;
          text-align: left;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & .extra-fields{
          display: inline-block;
          max-width: 49%;
        }
      }

      > .actions  {
        margin-right: 5px;
        /*.col(5);*/
        position: absolute!important;

        @media @xlarge {/*.col(3);*/float: right;display: none;};
        display: none;
        z-index: 99;
        height: @list-item-height - 2px;
        line-height: @list-item-height;
        right: 4rem;
        & .bnt-wrapper{

          height: 80px;
          line-height: 80px;
          display: inline-block;
          vertical-align: middle;

          > .item-btn {
            display: block;
            vertical-align: middle;
            @media @large, @xlarge{ width: 110px;};
            width: 110px;
            margin-top: 5px;
            border: 1px solid @brand-second;
            .border-radius();
            height: 40px;
            padding: 5px;
            &.remove p{
              color: @brand-second;
              text-transform: none;
              font-weight: bold;
              line-height: 40px;
              font-size: 1.3rem;
              font-size: 13px;
              &:before {
                content: '\e944';
                font-family: hollyglyph !important;
                font-size: 2.2rem;
                float: left;
              }
            }
            &.activation p{
              color: @brand-second;
              text-transform: none;
              font-weight: bold;
              line-height: 40px;
              font-size: 1.3rem;
              font-size: 13px;
            }
            & p{
              color: @brand-second;
              text-transform: none;
              font-weight: normal;
              line-height: 40px;
            }
            &:first-child{margin-top: 0px;}
            &:after{display: none;}

          }
          > .item-dropdown {
            display: inline-block;
            margin-left: 15px;
            border: 2px solid #9b8097;
            height: 34px;
            vertical-align: middle;
            line-height: 34px;
            > a{

              width: 12px;
              height: inherit;
              line-height: inherit;
              vertical-align: middle;
              margin-left: 12px;
              //A REPRENDRE C'EST DEGUEULASSE
              > .caret {
                color: @brand-primary;
              }
            }
          }
        }
      }
      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        padding: @simple-padding;
        z-index: 1;
      }
      & .active-cart{
        width:110px;
        display: inline-block;
        vertical-align: middle;
        padding: 7px;
        border: 1px solid @brand-second;
        color: @brand-second;
        .border-radius(3);
        .font(12);
        position: absolute;
        right: 5rem;
        top: 4rem;
        & p{
          .icon-check();
          margin: 0;
          .font(13);
          color: @brand-second;
          font-weight: bold;
          &:after {
            float: left;
            font-size: 2rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

// ############### ANIMATIONS & BEHAVIOURS ###############  //
.cart-list{
  li{
    &:hover{
      z-index: 3;

      & .item-paginate{
        outline: 1px solid @brand-second;
        > a{
          color: @brand-second;
        }
      }

      & .item{
        border-right: 5px solid @brand-second;
        > a {
          color: @brand-second;
          cursor: pointer;
        }

        > .caption{
          > .media-title{
            > a {
              color: @brand-second;
            }
          }
        }
        & .active-cart {
          font-size: 0;
          position: absolute;
          right:1rem;
          top:-1rem;
          border: 0;
          width: 20px;
          padding: 0;
          .transition(top, 0.5s);
          & p {
            font-size: 0;
          }
        }
        & .actions{
          display: inline-block;
        }

        & .selector-container {
          visibility: visible;
        }
      }
      .bnt-wrapper{
        display: block;
      }
    }

    ///END HOVER


    .selected {
      z-index: 3;
      border-right: 5px solid @brand-second;
      a {
        color: @brand-second;
        cursor: pointer;
      }

      & .caption{
        & .media-title{
          & a {
            color: @brand-second;
          }
        }
      }
      & .selector-container{
        visibility: visible;
        z-index: 3;

        & input {
          display: inline-block;
          line-height: 10px;
          .icon-checkbox_on();
          z-index: 999;

          &:before{
            color:#ffffff;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}