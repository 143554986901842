@footer-height : 100px;
footer {

  .col(12);
  .font(13);

  margin: 0 auto;

  color: @font-ultralight-color;
  text-align: center;
  font-family:@font-family-base;
  height: @footer-height; 
  line-height: @footer-height; 
  vertical-align: middle;
  & .layer {
    max-width: 1480px;
    margin: 0 auto;
  }
  .spacer {
    margin: 0 5px;
    display: inline-block;
  }

  a, span{
    color: @font-ultralight-color;
    text-decoration: underline;
    display: inline-block;


    &:hover,
    &:focus {
      color: @nav-link-hover-color;

    }
  }
}