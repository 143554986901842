
/**
 * Pagination
 */

.paginator-container{
clear: both;
.col(12);
float: right;

// border-bottom: 1px solid @border-light;
// border-top: 4px solid @brand-primary;
margin-top: @double-padding;
.clearfix();

} 
.pagination {
  margin: 0 auto;
.container(auto, none, 60);
display: inline-block;

}
.pagination a, .pagination span {
  color: @font-light-color;
  font-weight: normal;
  font-size: 13px;
  vertical-align: middle;
  display: table-cell;
  
  
  & .sr-only{display:none;}
}

.pagination .active a {
  color: #fff;
  background-color: @brand-second;
  .border-radius();
  display: table-cell;
  &:hover {
    color: #fff;
    background-color: @brand-primary;
  }
}

.pagination .next {
.icon-right_bold();
.font(13);

}

.pagination .previous {
.icon-left_bold();
.font(13);
}

  .pagination {
    
    display: table;
    & li {
      .border-radius();
      display: inline-block;
        &:hover {
          & a{
          color: #fff;
          &:after{color: #fff;}        
          }
    
    background-color: @brand-primary;
  }
  & .disabled{
     &:hover {
    background-color: transparent;
    }
  }
      list-style: none;
      vertical-align: middle;
      // outline: 1px  @border-light;
      .container(40,left,40);
      display: table;
      text-align: center;
    }

  }

