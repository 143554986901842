
/**
 * Gallery
 */
body.galleries {
   .gallery-thumbnail {
      .main-thumbnail {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 195px;
      }
    }
  & .galleries-container {
    .row();
    margin-bottom: 50px;
    & .layer {
      max-width: 1480px;
      margin: 0 auto;
      padding: 0 25px;
      & .btn-icon:after {
        margin-left: 0;
      }
    }
  }
  .gallery-record {
    & .thumbnail-wrapper {
      & img {
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
  }

  .galleries_list {
    margin-top: @double-padding;
    .list-col {
      @item-gutter: 15px;
      padding-left: @item-gutter;
      padding-bottom: @item-gutter;
      .items(@items-default);
      @media @xlarge{.items(@items-xlarge);}
      @media @large{.items(@items-large);}
      @media @desktop{.items(@items-desktop);}
      @media @tablet{.items(@items-tablet);}
      @media @mobile{.items(@items-mobile);}
      margin: -1px 0 0 0;
    }
  }
  .gallery-item {
    border: 1px solid #999;
    a {
      text-decoration: none;
      &:hover {
        h3, strong, span {
          color: @brand-primary;
        }
      }
    }
    height: 365px;
    padding: @simple-padding;

    h3 {
      font-size: 17px;
      font-family: @font-family-base;
      color: #5E5E5E;
      font-weight: 300;
      padding: @simple-padding 0;
      float: left;
      width: 85%;
      margin: 0;
      height: 65px;
      line-height: 2.2rem;
    }
    .gallery-thumbnail {
      width:100%;
      height:200px;
      display: table;
      & .thumbnail-wrapper {
        vertical-align: middle;
        display: table-cell;
        & img {
          width: 100%;
        }
      }
    }
    .gallery_counter {
      color: #4a63ae;
      float: right;
      margin-top: 20px;
      font-size: 11px;
      text-align: center;
      width: 15%;
      strong {
        line-height: 12px;
        text-align: center;
        display: block;
        font-size: 24px;
        margin-bottom: 4px;
      }
    }

    ul.mini-squares {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;

      li {
        margin: 0;
        width: 25%;
        float: left;
        padding-top: 3px;
        padding-right: 3px;
        height: 70px;
        &.last {
          padding-right: 0;
        }
        .square-thumbnail {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}



// ############################################  JUSTIFY GALLERY OVERWRITE ############################################ //



//  ###  This part is important if you use justifiedGallery to make <figure> placement it override the justifiedGallery Css ####  //
.justified-gallery > figure > a > img {margin: 0 !important;}
.justified-gallery > figure {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  margin: 0;
  & a {
    width: 100%;
    height: 100%;
    display: block;
  }
  &:hover {
    & > .caption {
      bottom: 0;
      & .bnt-wrapper {
        display: inline-block;
      }
    }
    & ul {
      position: absolute;
      list-style: none;
      bottom: 14.5rem;
      right: 1rem;
    }
  }
}

//// ##### Captions

.justified-gallery > figure > .caption,
.justified-gallery > figure > .caption {
  // background: url('../img/bgd_alpha_white.png');
  background: rgba(255, 255, 255, 0.95);
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#85FFFFFF,endColorstr=#85FFFFFF)";
  height: 50px;
  display: table;
  vertical-align: middle;
  width: 100%;
  position: absolute;
  bottom: -80px;
  .transition(bottom, 0.7s);
  margin-bottom: -1px;   //// fix an issue on the grid
  z-index: 3;
  & .bnt-wrapper{
    display: none;
    vertical-align: top;
    width: 70px;
    text-align: right;
    float: right;
    top: -131px;
    right: 0;
    position: relative;
    & .item-btn {
      @media @large, @xlarge{ width: 200px;};
      width: 160px;
      margin-top: 5px;
      display: block;
      vertical-align: middle;
      &:first-child{margin-top: 0px;}
      &:after{display: none;}
    }
    & .btn-icon {
      color: @font-dark-color;
    }
  }
  & h5 {
    padding: 15px;
  }
  & a {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: relative;
    font-size: 14px;
    height: 30px;
    display: block;
    position: relative;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow-multiline:ellipsis;
    color: @font-dark-color;
  }
  a:hover{text-decoration: none;}

  & div{
    width: 70px;
    top: -50%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    position: relative;
    display: inline-block;
    height: 30px;
    float: right;

    button {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      display: inline-block;
      background: rgba(255, 255, 255, 0.85);
      height: 30px;
      float: right;
      width: 30px;
      padding: 0;
    }
  }
}