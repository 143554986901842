@nav-height: 150px;
.main-nav{}

.logo-container{
  .row();
  height: @nav-height;
  line-height: @nav-height;
  text-align: center;
  display: block;
  @media @mobile, @tablet{
    .container(100%);
  };

  & a {     
    display: block;
    line-height: inherit;
    vertical-align: center;
    margin: auto;
    width: 30%;
    height: 100%;
    .bg-contain(@white-logo-png,center,center);
    @media @mobile, @tablet {
      .bg-contain(@white-logo-png, center, center);
      width: 60%;
    }
  }
}

.navbar{
  .row();
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family:@font-family-base;
  color: @font-base-color;
  .font-size(13);
  vertical-align: top;
  margin-top: -1px;
  text-align: center;
    @media @mobile, @tablet{
      .row();
      height: auto;
    };
  & ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    height: @header-height;
    line-height: @header-height;
    vertical-align: middle;
    margin-top: -1px;
    @media @mobile, @tablet{
      .row();
      height: auto;
    };

    & .active{
      & a {
      color: @brand-primary!important;
      }
    }

  & li {
    display: inline-block;
    color: @font-base-color;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    padding:0;
    font-weight: 300;
    height: inherit;
    line-height: inherit;
    margin-top: -1px;
    margin-right: 35px;
    margin-left: 35px;

    &:hover{
      cursor: pointer;
      color: @brand-secondary;
    }

    &:first-child{
      padding-left: @simple-padding;
     
      @media @mobile, @tablet{
        padding: 0;
      };
    }

    > a {
      position: relative;
      display: block;
      color: inherit;
      font-family: 'gilroyheavy';
      .font-size(15);
      font-weight: 300;
      line-height: inherit;
      text-transform: uppercase;
      padding: 0 @simple-padding;
     
      &:hover,
      &:focus {
        text-decoration: none;
        color: @nav-link-hover-color;
        &:before {
          content:'';
          @-webkit-keyframes rotating /* Safari and Chrome */ {
            from {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes rotating {
            from {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -ms-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          display: inline-block;
          width: 25px;
          height: 24.4px;
          background-image: url("../img/icons/exhibit-icon.png");
          background-size: contain;
          position: absolute;
          left: -2rem;
          top: 2.2rem;
          -webkit-animation: rotating 1.8s ease infinite;
          -moz-animation: rotating 1.8s ease infinite;
          -ms-animation: rotating 1.8s ease infinite;
          -o-animation: rotating 1.8s ease infinite;
          animation: rotating 1.8s ease infinite;
        }
      }
    }
  }
}


  .filters-wrapper.collapse {
    display: block;
  }

  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: -15px;
  }

}
