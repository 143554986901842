
body.account{
  & .container{
    float: none;
    margin: auto;
    & .account-container{
      margin: 50px 0;
      .border-radius(5);
      .drop-shadow();  
      .col(6);
      .push(3);
      @media @tablet {
        .col(8);
        .push(2);
      };
      @media @mobile {
        .col(10);
        .push(1);
      };
      padding: 0 @double-padding + @double-padding @double-padding;
      background-color: #ffffff;
    }
    & h1{
      .font(52);
      font-family:@font-family-base;
      color: @font-dark-color;
      padding: @double-padding 0;
      text-transform: uppercase;
      font-weight: 700;
    }
    & h2{
      font-family:@font-family-base;
      color: @font-base-color;
    }
    & .content-header{display: none;}
    & header{
      & .form-search{display: none;}
      // & .navbar{display: none;}
    }
    & input, select {
      max-width: inherit;
    }
    & label {
      color: @font-dark-color;
    }
  }
}

